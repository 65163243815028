<template>
  <div class="serveDetail">
    <div class="detail">
      <div class="info">
        <div class="info-title">
          <span>上报信息</span>
          <span>{{ detailData.status | filterStatus }}</span>
        </div>
        <div class="label">
          <span>设备名称：</span>
          <span>{{ detailData.equipmentName }}</span>
        </div>
        <div class="label">
          <span>损坏说明：</span>
          <span>{{ detailData.malfunctionDescription }}</span>
        </div>
        <div class="label">
          <span>上报时间：</span>
          <span>{{ detailData.applyTime }}</span>
        </div>
        <div class="label">
          <span>报修人：</span>
          <span>{{ detailData.applyPeople }}</span>
        </div>
        <!-- <div class="label">
          <span>设备类型：</span>
          <span>{{ detailData.majorType }}</span>
        </div> -->
        <div class="label">
          <span>维修地点：</span>
          <span>{{ detailData.spaceName }}</span>
        </div>
      </div>
      <div class="content" v-if="detailData.status > 1">
        <div class="fault-message order-receiving">
          <div class="title">接单信息</div>
          <div class="item">
            <div class="label">
              <span>接单人：</span>
              <span>{{ detailData.receiver }}</span>
            </div>
            <div class="label">
              <span>接单时间：</span>
              <span>{{ detailData.receiveTime }}</span>
            </div>
            <div class="label">
              <span>专业类别：</span>
              <span>{{ detailData.majorType }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="content"
        v-if="detailData.status == 3 || detailData.status == 5"
      >
        <div class="fault-message">
          <div class="title">处理完成信息确认</div>
          <div class="form-item">
            <div class="form-item-title">设备名称</div>
            <div class="select">
              <div>{{ detailData.equipmentName }}</div>
            </div>
            <div class="form-item-title">维修情况描述</div>
            <div class="textarea">
              <v-input
                disabled
                type="textarea"
                v-model="detailData.repairDescription"
                placeholder="输入备注故障信息"
              ></v-input>
            </div>
            <div class="form-item-title">上传照片</div>
            <div class="photo">
              <v-upload
                disabled
                :imgUrl.sync="detailData.repairPic"
                :activityPicture.sync="detailData.repairPic"
                class="oItem-b_upload"
                ref="load"
              ></v-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer" v-if="detailData.status == 3">
      <!-- <div class="serve-btn">
        <div class="btn">无法处理，申报上级</div>
        <div class="btn">接收工单</div>
      </div> -->
      <div class="finish-btn">
        <div class="btn" @click="isShowDialog = true">去评价</div>
      </div>
    </div>
    <div class="dialog" v-if="isShowDialog">
      <div class="dialog-content">
        <div class="title">
          <span>请选择</span>
          <img @click="isShowDialog = false" src="./img/close.png" alt="" />
        </div>
        <div class="img-icon">
          <img src="./img/flow.png" alt="" />
        </div>
        <div class="select">
          <span @click="handelClick">{{
            dialogData.evaluate ? dialogData.evaluate : "请选择"
          }}</span>
          <img src="./img/arrow.png" alt="" />
        </div>
        <div class="footer">
          <span @click="isShowDialog = false">取消</span>
          <span @click="confirmSure">确认</span>
        </div>
      </div>
    </div>
    <v-picker
      :columns="list"
      :valueShow="isShow"
      :valueKey="eventKey"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
  </div>
</template>

<script>
import { getWorkInfoById, saveOrUpdateWorkInfo } from "./api.js";
export default {
  name: "workOrderServeDetail",
  props: {},
  data() {
    return {
      isShow: false,
      eventKey: "label",
      isShowDialog: false,
      active: 2,
      detailData: {},
      dialogData: {
        evaluate: "",
        value: "",
      },
      list: [
        {
          value: 1,
          label: "非常满意",
        },
        {
          value: 2,
          label: "满意",
        },
        {
          value: 3,
          label: "一般",
        },
        {
          value: 4,
          label: "不满意",
        },
        {
          value: 5,
          label: "非常不满意",
        },
      ],
    };
  },
  created() {},
  mounted() {
    const { id } = this.$route.query;
    if (id != undefined) {
      this.getWorkInfoById(id);
    }
  },
  filters: {
    filterStatus(status) {
      const obj = {
        // 工单状态: 1已受理-2待服务-3服务中-4待反馈(已处理)-5已反馈(待审核)-6已完成（审核通过）
        1: "待接单",
        2: "服务中",
        3: "待评价",
        5: "已完成",
      };
      return obj[status];
    },
  },
  methods: {
    confirmSure() {
      const params = {
        evaluateForm: {
          id: this.detailData.id,
          satisfaction: this.dialogData.value,
        },
      };
      this.$axios.post(saveOrUpdateWorkInfo, params).then((res) => {
        if (res.code == 200) {
          this.isShowDialog = false;
        }
      });
    },
    clickOverlay() {
      this.isShow = false;
    },
    cancelPick() {
      this.isShow = false;
    },
    confirm(node) {
      this.dialogData.evaluate = node.label;
      this.dialogData.value = node.value;
      this.isShow = false;
    },
    handelClick(id) {
      this.isShow = true;
    },
    getWorkInfoById(id) {
      this.$axios
        .get(getWorkInfoById, {
          params: {
            id,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.detailData = res.data;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.serveDetail {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  .detail {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    background: #f8f8f8;
    .info {
      background-image: url("./img/bg.png");
      background-repeat: no-repeat;
      background-size: 100% 122%;
      padding: 44px 30px 22px 30px;
      box-sizing: border-box;
      .info-title {
        margin-bottom: 28px;
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
        display: flex;
        justify-content: space-between;
        span {
          &:last-child {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fe4416;
            line-height: 40px;
          }
        }
      }
      .label {
        span {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 56px;
          &:last-child {
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
    .content {
      padding: 0 30px 24px 30px;
      width: 100%;
      box-sizing: border-box;
      .steps {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 542px;
        background: #ffffff;
        border-radius: 16px;
        padding: 28px 34px 44px 34px;
        box-sizing: border-box;

        .steps-content {
          flex: 1;
          .van-steps--vertical {
            padding: 0 0 0 45px;
            box-sizing: border-box;
          }
          .status-icon {
            width: 26px;
            height: 26px;
            text-align: center;
            line-height: 26px;
            background: #efefef;
            border-radius: 50%;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #c1c1c1;
            &.active-icon {
              color: #ffffff;
              background: #fe4416;
            }
          }
          .finish-icon {
            width: 26px;
            height: 26px;
          }

          .text {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 40px;
          }
          .time {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 34px;
          }
          ::v-deep .van-hairline {
            &::after {
              content: none;
            }
          }
        }
      }
      .address {
        width: 100%;
        min-height: 174px;
        background: #ffffff;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 30px 24px 32px 34px;
        margin-block: 22px;
        .address-info {
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .address-info-text {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
            &.text {
              color: #fe4416;
            }
          }
          .navigation {
            flex-shrink: 0;
          }
        }
      }
      .fault-message {
        width: 100%;
        min-height: 856px;
        background: #ffffff;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 30px 34px 60px 34px;
        margin-bottom: 22px;
        .form-item {
          width: 100%;
          box-sizing: border-box;
          .form-item-title {
            margin-top: 44px;
            margin-bottom: 22px;
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
          }
          .select {
            width: 100%;
            height: 68px;
            background: #f3f3f3;
            border-radius: 10px;
            padding: 0 36px 0 26px;
            box-sizing: border-box;
            > div {
              height: 100%;
              line-height: 68px;
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            }
          }
          .textarea {
            height: 174px;
            background: #f3f3f3;
            border-radius: 10px;
            overflow: hidden;
            > div {
              height: 100%;
              width: 100%;
            }
            ::v-deep .van-cell {
              height: 100%;
              width: 100%;
              background: #f3f3f3;
              .van-field__body {
                width: 100%;
                height: 100%;
                textarea {
                  height: 100%;
                }
              }
              input.van-field__control::-webkit-input-placeholder {
                color: rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
      .order-receiving {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 296px;
        background: #ffffff;
        border-radius: 16px;
        box-sizing: border-box;
        padding: 30px 34px 60px 34px;
        margin-bottom: 22px;
        .item {
          margin-top: 32px;
          box-sizing: border-box;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .label {
            span {
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
              &:last-child {
                font-size: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
              }
            }
          }
        }
      }
    }
  }
  .title {
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 44px;
    img {
      width: 22px;
      height: 22px;
    }
  }

  .footer {
    width: 100%;
    background: #ffffff;
    box-sizing: border-box;
    padding: 48px 30px 48px 0;
    .serve-btn {
      display: flex;
      justify-content: flex-end;
      gap: 28px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      .btn {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #fe4416;
        line-height: 74px;
        text-align: center;
        &:first-child {
          width: 346px;
          height: 74px;
          background: #ffffff;
          border-radius: 74px;
          border: 2px solid #fe4416;
        }
        &:last-child {
          width: 226px;
          height: 74px;
          background: #fe4416;
          color: #ffffff;
          border-radius: 74px;
        }
      }
    }
    .finish-btn {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      .btn {
        width: 226px;
        height: 74px;
        background: #fe4416;

        border-radius: 74px;
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 74px;
        text-align: center;
      }
    }
  }
  .dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    left: 0;
    top: 0;
    .dialog-content {
      z-index: 999;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 40px 40px 60px 40px;
      background: linear-gradient(180deg, #f8faff 0%, #ffffff 100%);
      border-radius: 16px;
      box-sizing: border-box;
      .title {
        margin-bottom: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .img-icon {
        box-sizing: border-box;
        padding: 0 36px 0 34px;
        height: 68px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .select {
        margin-top: 74px;
        width: 480px;
        height: 68px;
        background: #f3f3f3;
        border-radius: 10px;
        padding: 0 40px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          flex: 1;
          padding-right: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
      .footer {
        margin-top: 130px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 28px;
        box-sizing: border-box;
        span {
          text-align: center;
          line-height: 74px;
          background: #ffffff;
          border-radius: 74px;
          border: 2px solid #fe4416;
          flex: 1;
          height: 74px;
          font-size: 30px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #fe4416;
          &:last-child {
            color: #ffffff;
            background: #fe4416;
          }
        }
      }
    }
  }
}
</style>
